export default {
  component: () => import('./page.vue'),
  path: '/payments/:type(unpaid|paid)',
  name: 'payments',
  props: true,
  meta: {
    title: '缴费记录',
    icon: 'gold-coin-o',
    color: 'text-sky-500',
    wechat: {
      openTagList: ['wx-open-launch-weapp'],
    },
  },
};
