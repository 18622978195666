import { closeToast, showFailToast, showLoadingToast } from 'vant';

import { login } from '@eaphone/auth';
import { isAndroid } from '@eaphone/env';
import { Auth } from '@eaphone/storage';

function wechatUrl(url) {
  const io = new URL(
    './connect/oauth2/authorize',
    'https://open.weixin.qq.com',
  );

  io.searchParams.set('appid', import.meta.env.APP_ID);
  io.searchParams.set('redirect_uri', url);
  io.searchParams.set('response_type', 'code');
  io.searchParams.set('scope', 'snsapi_userinfo');
  io.searchParams.set('state', '');

  io.hash = '#wechat_redirect';

  return io.href;
}

function fullUrl(url) {
  /* globals __webpack_public_path__: readonly */
  return new URL(
    (__webpack_public_path__ + url).replaceAll(/\/+/g, '/'),
    window.location.origin,
  );
}

function replace({ path, query }) {
  const io = fullUrl(path);

  for (const [key, value] of Object.entries(query)) {
    io.searchParams.set(key, value);
  }

  window.location.replace(io.href);
}

function loginLogic(next, to) {
  showLoadingToast('正在登录...');
  const {
    path,
    query: { code, state, ...query },
  } = to;
  login({ code, type: 'WechatMp' })
    .then(() => {
      closeToast();

      if (isAndroid) {
        next({ path, query, replace: true });
      } else {
        replace({ path, query });
      }
    })
    .catch((error) => {
      console.error(error);
      showFailToast(error.message);
      next({ name: 'error-401' });
    });
}

export function loginHook(to, from, next) {
  if (to.meta.auth === false) {
    next();
  } else if (to.query.code) {
    loginLogic(next, to);
  } else if (Auth.token) {
    next();
  } else {
    const url = wechatUrl(fullUrl(to.fullPath).href);
    window.location.replace(url);
  }
}

export { usePageConfig } from './mixin.js';

const shareInfo = {
  title: '暨南大学附属第一医院',
  desc: '广州华侨医院',
  link: fullUrl('/'),
  imgUrl: fullUrl('/favicon.png'),
};

const sdk = {
  fullUrl,
  updateTimelineShareData(info) {
    wx.updateTimelineShareData({ ...shareInfo, ...info });
  },
  updateAppMessageShareData(info) {
    wx.updateAppMessageShareData({ ...shareInfo, ...info });
  },
};

document.addEventListener(
  'WeixinOpenTagsError',
  (error) => {
    console.error(error);
  },
  false,
);

export function sdkHook() {
  return new Promise((resolve, reject) => {
    wx.ready(() => {
      resolve(sdk);
    });
    wx.error((error) => {
      reject(new Error(error.errMsg));
    });
  });
}

export function openMap({ name, address, longitude, latitude }) {
  sdkHook()
    .then(() => {
      wx.openLocation({
        longitude,
        latitude,
        name,
        address,
      });
    })
    .catch(() => {});
}

export function callPay(io) {
  return new Promise((resolve, reject) => {
    if (io.payUrl) {
      window.location.replace(io.payUrl);
    } else {
      WeixinJSBridge.invoke('getBrandWCPayRequest', io, (res) => {
        switch (res.err_msg) {
          case 'get_brand_wcpay_request:ok': {
            resolve(true);
            break;
          }
          case 'get_brand_wcpay_request:cancel': {
            resolve(false);
            break;
          }
          case 'get_brand_wcpay_request:fail': {
            reject();
            break;
          }
          default:
        }
      });
    }
  });
}

export function callMiniOneWay() {}

export function callMiniTwoWay() {}

function setFontSizeCallback(fontSize) {
  WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: String(fontSize) });
}

function handleFontSize() {
  setFontSizeCallback(2);
  WeixinJSBridge.on('menu:setfont', ({ fontSize: num }) => {
    const number = Number(num);
    const fontSize = number > 5 ? 5 : Math.max(number, 2);

    setFontSizeCallback(fontSize);
  });
}

if (isAndroid) {
  if (
    typeof WeixinJSBridge === 'object' &&
    typeof WeixinJSBridge.invoke === 'function'
  ) {
    handleFontSize();
  } else {
    document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
  }
}
