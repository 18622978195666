export default {
  component: () => import('./page.vue'),
  path: '/schedule/:id',
  name: 'schedule',
  props: true,
  meta: {
    title: '门诊排班表',
    wechat: {
      openTagList: ['wx-open-launch-weapp'],
    },
  },
};
