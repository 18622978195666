import { computed, watch } from 'vue';

import { setTitle } from './toolkit.js';

export function useTitle(route) {
  const pageTitle = computed(() => route.meta?.title);

  watch(pageTitle, (newTitle, oldTitle) => {
    if (newTitle && newTitle !== oldTitle) {
      setTitle(newTitle);
    }
  });
}
