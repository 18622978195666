import 'dayjs/locale/zh-cn';

import dayjs from 'dayjs';

// import duration from 'dayjs/plugin/duration.js';
// import updateLocale from 'dayjs/plugin/updateLocale.js';
import pkg from '@eaphone/design/package.json';

dayjs.locale('zh-cn');

// dayjs.extend(duration);
// dayjs.extend(updateLocale);

// dayjs.updateLocale('en', {
//   weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
// });

export { dayjs };

export function weekFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('dddd') : undefined;
}

export function datetimeFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss') : undefined;
}

export function timeFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('HH:mm') : undefined;
}

export function dateFormat(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY-MM-DD') : undefined;
}

export function monthFormatCN(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY 年 M 月') : undefined;
}

export function dayFormatCN(timestamp) {
  return timestamp ? dayjs(timestamp).format('M 月 D 日') : undefined;
}

export function dateFormatCN(timestamp) {
  return timestamp ? dayjs(timestamp).format('YYYY 年 M 月 D 日') : undefined;
}

export function dateWeekFormatCN(timestamp) {
  return timestamp ? dayjs(timestamp).format('M 月 D 日 dddd') : undefined;
}

export function datetimeWeekFormatCN(timestamp) {
  return timestamp
    ? dayjs(timestamp).format('YYYY 年 M 月 D 日 dddd A HH:mm')
    : undefined;
}

export function maskPhone(value) {
  // eslint-disable-next-line regexp/no-unused-capturing-group
  return /^(\+86)?1\d{10}$/.test(value)
    ? `${value.slice(0, -8)}****${value.slice(-4)}`
    : value;
}

export function setTitle(title) {
  document.title = (
    import.meta.env.WATCHING ? [title, '本地调试中'] : [title, pkg.i18n.name]
  ).join(' | ');
}

function getDecimalPlaces(number) {
  const numStr = number.toString();
  const dotIndex = numStr.indexOf('.');

  if (dotIndex === -1) {
    return 0;
  }

  return numStr.length - dotIndex - 1;
}

export function toFixedSmart(num) {
  return num === undefined || num === null
    ? '--'
    : num.toFixed(getDecimalPlaces(num) > 2 ? 4 : 2);
}

function ceil(num, precision) {
  const prec = 10 ** precision;

  return Math.ceil(num * prec) / prec;
}

export function rmbRaw(price = '') {
  return price === 0 || price ? `￥${price}` : price;
}

export function rmb(price = '') {
  return rmbRaw(ceil(price, 2));
}

export function maskCard(value) {
  return value?.length > 8
    ? value.replace(
        /^(\w{4})(\w+)(\w{4})$/,
        `$1${'*'.repeat(value.length - 8)}$3`,
      )
    : value;
}
