export const route = {
  component: () => import('./page.vue'),
  path: '/home',
  name: 'home',
  meta: {
    title: '首页',
    wechat: {
      openTagList: ['wx-open-launch-weapp'],
    },
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'home-o',
  to: {
    name: route.name,
  },
};
