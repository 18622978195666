export default {
  component: () => import('./page.vue'),
  path: '/eula',
  name: 'eula',
  meta: {
    title: '用户授权协议',
    icon: 'label-o',
    color: 'text-lime-500',
    auth: false,
  },
};
