export const route = {
  component: () => import('./page.vue'),
  path: '/info',
  name: 'info',
  meta: {
    title: '医院信息',
    wechat: {
      jsApiList: ['openLocation'],
    },
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'hotel-o',
  to: {
    name: route.name,
  },
};
