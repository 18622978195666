export default {
  component: () => import('./page.vue'),
  name: 'report-history',
  path: '/report/history',
  props: true,
  meta: {
    title: '报告查询',
    icon: 'notes-o',
    color: 'text-amber-500',
  },
};
