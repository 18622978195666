export default {
  component: () => import('./index.vue'),
  path: '/chs/:type(settle|registration)/:id',
  props: true,
  name: 'chs',
  meta: {
    title: '收银台',
    nhsamp: true,
    wechat: {
      openTagList: ['wx-open-launch-weapp'],
    },
  },
};
