import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.async-iterator.some.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
function _newArrowCheck(n, r) { if (n !== r) throw new TypeError("Cannot instantiate an arrow function"); }
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_van_floating_bubble = _resolveComponent("van-floating-bubble");
  var _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    class: _normalizeClass(["select-none overflow-auto", {
      dark: $setup.isDark,
      'h-full': _ctx.$route.name === 'department'
    }]),
    theme: $setup.isDark ? 'dark' : 'light'
  }, {
    default: _withCtx(function () {
      var _this2 = this;
      _newArrowCheck(this, _this);
      return [_renderSlot(_ctx.$slots, "default"), $setup.isInWechat && $setup.isAndroid && $setup.route.matched.length > 0 && !$setup.route.matched.some(function (_ref) {
        _newArrowCheck(this, _this2);
        var name = _ref.name;
        return name === 'main';
      }.bind(this)) ? (_openBlock(), _createBlock(_component_van_floating_bubble, {
        key: 0,
        offset: $setup.offset,
        "onUpdate:offset": _cache[0] || (_cache[0] = function ($event) {
          _newArrowCheck(this, _this2);
          return $setup.offset = $event;
        }.bind(this)),
        axis: "xy",
        icon: "arrow-left",
        onClick: $setup.back
      }, null, 8 /* PROPS */, ["offset"])) : _createCommentVNode("v-if", true)];
    }.bind(this)),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "theme"]);
}