export const route = {
  component: () => import('./page.vue'),
  path: '/mine',
  name: 'mine',
  meta: {
    title: '个人中心',
    //
  },
};

export const tab = {
  label: route.meta.title,
  icon: 'contact-o',
  to: {
    name: route.name,
  },
};
