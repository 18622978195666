import 'vant/es/dialog/style';
import 'vant/es/toast/style';

import { setToastDefaultOptions } from 'vant';

export function VantInstall() {}

VantInstall.install = function install() {
  setToastDefaultOptions({ forbidClick: true, overlay: true, duration: 2500 });
  setToastDefaultOptions('loading', { duration: 0 });
};
