export default {
  component: () => import('./page.vue'),
  path: '/registration/history',
  name: 'registration-history',
  meta: {
    title: '挂号记录',
    icon: 'flag-o',
    color: 'text-indigo-500',
    wechat: {
      openTagList: ['wx-open-launch-weapp'],
    },
  },
};
