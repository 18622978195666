export default {
  component: () => import('./page.vue'),
  path: '/department/:hospital?',
  name: 'department',
  props: true,
  meta: {
    title: '科室列表',
    icon: 'apps-o',
    color: 'text-teal-500',
    wechat: {
      jsApiList: ['openLocation'],
    },
  },
};
