export default {
  name: 'cards',
  path: '/cards',
  component: () => import('./page.vue'),
  meta: {
    title: '我的就诊卡',
    icon: 'idcard',
    color: 'text-purple-500',
  },
};
