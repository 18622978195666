import { PetShop } from 'pet-shop';

const namespace = `hospital-${import.meta.env.CONFIG_NAME}`;

export const localStore = PetShop({
  namespace,
  storage: window.localStorage,
  json: true,
});

export const sessionStore = PetShop({
  namespace,
  storage: window.sessionStorage,
  json: true,
});

export const temp1 = PetShop({
  namespace: `${namespace}.temp1`,
  storage: window.sessionStorage,
  json: true,
});

export const temp2 = PetShop({
  namespace: `${namespace}.temp2`,
  storage: window.sessionStorage,
  json: true,
});
