export default {
  component: () => import('./page.vue'),
  name: 'inpatient-log',
  path: '/inpatient/log/:id?',
  props: true,
  meta: {
    icon: 'completed-o',
    title: '住院费清单',
    color: 'text-lime-500',
  },
};
