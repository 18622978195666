import { pickBy } from 'lodash';

import { useRouter } from 'vue-router';

export function routeMapper(routeMap) {
  return Object.entries(routeMap).map(
    ([defaultPath, { path = defaultPath, props, ...config }]) => {
      return {
        ...config,
        path,
        props:
          props === true && path.includes('?')
            ? (route) =>
                pickBy(
                  { ...route.query, ...route.params },
                  (item) => item !== '',
                )
            : props,
      };
    },
  );
}

export function useBack() {
  const router = useRouter();

  return (route) => {
    if (window.history.length > 1) {
      router.back();
    } else {
      router.replace(route);
    }
  };
}
