const { userAgent } = navigator;

export const isInWechat = /micromessenger|weixin|wechat/i.test(userAgent);

export const isWechatDevtool = /wechatdevtools/i.test(userAgent);

export const isInAlipay = /alipay/i.test(userAgent);

export const isAlipayDevtool = /alipayide\sminiprogram/i.test(userAgent);

export const isDevtool = isAlipayDevtool || isWechatDevtool;

export const isIOS = /ip(ad|hone|od)/i.test(userAgent);

export const isAndroid = /android/i.test(userAgent);

export const isAlipay = import.meta.env.CONFIG_NAME === 'alipay';

export const isWechat = import.meta.env.CONFIG_NAME === 'wechat';
