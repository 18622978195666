import "core-js/modules/es.object.assign.js";
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDark } from '@vueuse/core';
import { isAndroid, isInWechat } from '@eaphone/env';
import { usePageConfig } from '@eaphone/logic';
import { useTitle } from '@eaphone/plugins/hook.js';
var __default__ = {};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'theme',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    __expose();
    var route = useRoute();
    usePageConfig(route);
    useTitle(route);
    var isDark = useDark();
    var offset = ref({});
    var router = useRouter();
    function back() {
      router.back();
    }
    var __returned__ = {
      route: route,
      isDark: isDark,
      offset: offset,
      router: router,
      back: back,
      ref: ref,
      get useRoute() {
        return useRoute;
      },
      get useRouter() {
        return useRouter;
      },
      get useDark() {
        return useDark;
      },
      get isAndroid() {
        return isAndroid;
      },
      get isInWechat() {
        return isInWechat;
      },
      get usePageConfig() {
        return usePageConfig;
      },
      get useTitle() {
        return useTitle;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});