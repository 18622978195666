export default {
  component: () => import('./page.vue'),
  name: 'report',
  path: '/report/:type(lis|pacs)/:id',
  props: true,
  meta: {
    title: '报告详情',
    jsApiList: ['previewImage'],
  },
};
