export default {
  name: 'inpatient-users',
  path: '/inpatient/users',
  component: () => import('./page.vue'),
  meta: {
    title: '我的住院人',
    icon: 'user-o',
    color: 'text-emerald-500',
  },
};
