export default {
  component: () => import('./page.vue'),
  name: 'report-doctor',
  path: '/report/doctor/:type(lis|pacs)?',
  props: true,
  meta: {
    title: '临床试验报告',
    icon: 'description-o',
    color: 'text-teal-500',
  },
};
