function _newArrowCheck(n, r) { if (n !== r) throw new TypeError("Cannot instantiate an arrow function"); }
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.assign.js";
import { onMounted, provide, ref } from 'vue';
import Theme from '@eaphone/style/theme.vue';
import { setI18n } from './i18n.js';
var __default__ = {};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'app',
  setup: function setup(__props, _ref) {
    var _this = this;
    var __expose = _ref.expose;
    __expose();
    onMounted(function () {
      _newArrowCheck(this, _this);
      setI18n();
    }.bind(this));
    var cache = ref({});
    provide('form-cache', cache);
    var __returned__ = {
      cache: cache,
      onMounted: onMounted,
      provide: provide,
      ref: ref,
      Theme: Theme,
      get setI18n() {
        return setI18n;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});