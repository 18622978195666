import { createCache } from 'pet-shop';

import { localStore, sessionStore, temp1, temp2 } from './core.js';

export { localStore, sessionStore };

export const Auth = createCache(sessionStore, [
  'token',
  // 'openId',
]);

export const Temp1 = createCache(temp1, ['1', '2']);

export const Temp2 = createCache(temp2, ['1', '2']);

if (import.meta.env.WATCHING) {
  Auth.token =
    import.meta.env.CONFIG_NAME === 'alipay'
      ? 'c94349b9-91ab-11ef-bce8-d3d2b769e46e'
      : '1fcfadfc-6367-11ef-9bf3-e79e63a13cd5';
}
