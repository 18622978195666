import "core-js/modules/es.object.assign.js";
import TabBlock from './tab-block.vue';
var __default__ = {};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'page',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    __expose();
    var __returned__ = {
      TabBlock: TabBlock
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});