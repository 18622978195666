export default {
  component: () => import('./page.vue'),
  path: '/privacy-policy',
  name: 'privacy-policy',
  meta: {
    title: '隐私政策',
    icon: 'description-o',
    color: 'text-pink-500',
    auth: false,
  },
};
