import { route as Home } from './home/route.sub.js';
import { route as Info } from './info/route.sub.js';
import { route as Mine } from './mine/route.sub.js';

import component from './page.vue';

export default {
  component,
  name: 'main',
  path: '/(home|mine|info)',
  children: [Home, Info, Mine, Info],
};
