export default {
  component: () => import('./page.vue'),
  path: '/hospitals',
  props: true,
  name: 'hospitals',
  meta: {
    title: '院区列表',
    wechat: {
      jsApiList: ['openLocation'],
    },
  },
};
