export default {
  component: () => import('./page.vue'),
  path: '/doctor/fav',
  name: 'doctor-fav',
  meta: {
    title: '我的收藏',
    icon: 'star-o',
    color: 'text-yellow-500',
  },
};
