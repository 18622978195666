import "core-js/modules/es.error.cause.js";
function _newArrowCheck(n, r) { if (n !== r) throw new TypeError("Cannot instantiate an arrow function"); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock($setup["Theme"], null, {
    default: _withCtx(function () {
      _newArrowCheck(this, _this);
      return [_createVNode(_component_router_view)];
    }.bind(this)),
    _: 1 /* STABLE */
  });
}