import { Locale } from 'vant';

const i18n = {
  ar: () => import('vant/es/locale/lang/ar-SA'),
  de: () => import('vant/es/locale/lang/de-DE'),
  en: () => import('vant/es/locale/lang/en-US'),
  es: () => import('vant/es/locale/lang/es-ES'),
  fr: () => import('vant/es/locale/lang/fr-FR'),
  hi: () => import('vant/es/locale/lang/hi-IN'),
  ja: () => import('vant/es/locale/lang/ja-JP'),
  ko: () => import('vant/es/locale/lang/ko-KR'),
  pt: () => import('vant/es/locale/lang/pt-BR'),
  ru: () => import('vant/es/locale/lang/ru-RU'),
  th: () => import('vant/es/locale/lang/th-TH'),
  zh: () => import('vant/es/locale/lang/zh-CN'),
  'zh-HK': () => import('vant/es/locale/lang/zh-HK'),
  'zh-TW': () => import('vant/es/locale/lang/zh-TW'),
};

export function setI18n() {
  const lang = navigator.languages?.[0] || navigator.language;

  const getter = i18n[lang] || i18n[lang.split('-')[0]] || i18n.zh;

  if (getter) {
    getter()
      .then(({ default: langObject }) => {
        Locale.use(lang, langObject);
      })
      .catch(() => {});
  } else {
    console.log(lang);
  }
}
