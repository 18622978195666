import "core-js/modules/es.error.cause.js";
function _newArrowCheck(n, r) { if (n !== r) throw new TypeError("Cannot instantiate an arrow function"); }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  var _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createBlock(_component_van_tabbar, {
    route: ""
  }, {
    default: _withCtx(function () {
      var _this2 = this;
      _newArrowCheck(this, _this);
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabItems, function (_ref) {
        var _this3 = this;
        _newArrowCheck(this, _this2);
        var label = _ref.label,
          icon = _ref.icon,
          to = _ref.to;
        return _openBlock(), _createBlock(_component_van_tabbar_item, {
          key: label,
          icon: icon,
          name: to.name,
          replace: "",
          to: to
        }, {
          default: _withCtx(function () {
            _newArrowCheck(this, _this3);
            return [_createTextVNode(_toDisplayString(label), 1 /* TEXT */)];
          }.bind(this)),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon", "name", "to"]);
      }.bind(this)), 128 /* KEYED_FRAGMENT */))];
    }.bind(this)),
    _: 1 /* STABLE */
  });
}