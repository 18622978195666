import ky from 'ky';
import { pickBy } from 'lodash';

import { callMiniOneWay, callMiniTwoWay } from '@eaphone/logic';
import { Auth } from '@eaphone/storage';

const statusErrors = {
  400: '发送的请求参数不正确',
  401: '登录超时，请重新登录',
  403: '没有访问该功能的权限',
  404: '找不到您要访问的内容',

  // 人为触发，只是利用其编号：
  408: '网络连接超时，请重试',
  415: '返回的数据格式不正确',
  418: '未知错误，请求失败',
  429: '请求已取消',
};

function clearToken() {
  Auth.clear();
  callMiniOneWay('logout');
}

/** 错误预设生成 */
export class KyError extends Error {
  constructor(
    code = 418,
    { message = statusErrors[code] ?? statusErrors[418], original } = {},
  ) {
    super(message);
    this.code = code;
    this.name = 'KyError';

    if (original) {
      this.original = original;
    }
  }
}

function mergeErrorJson(original) {
  const {
    errcode,
    errCode,
    errmsg,
    errMsg,
    error,
    errorMsg,
    message,
    success,
  } = original;

  return {
    code: errCode ?? errcode,
    error: success === false || error === true,
    message: message ?? errorMsg ?? errMsg ?? errmsg,
    original,
  };
}

function toJson(response) {
  return response.json().then(mergeErrorJson);
}

function typeCheck(type) {
  return (response) => {
    const ContentType = response.headers.get('Content-Type');

    return ContentType && ContentType.startsWith(type);
  };
}

const isJson = typeCheck('application/json');

function cleanObject(object) {
  const io = pickBy(object, (item) => item !== undefined);

  return Object.keys(io).length > 0 ? io : undefined;
}

export const beforeRequest = [
  function cleanSearchParams(request, options) {
    if (options.searchParams) {
      // eslint-disable-next-line no-param-reassign
      options.searchParams = cleanObject(options.searchParams);
    }
  },
  async function autoAddToken(request, options) {
    if (options.auth !== false) {
      // 请求前检测 token，通过 auth: false 可关闭此功能。
      const { token } = Auth;

      if (token) {
        // 默认检测 token 是否存在，存在则在请求头中添加 token。
        request.headers.set('Authorization', `Bearer ${token}`);
      } else {
        clearToken();
        // token 不存在时，取消请求并报错。
        throw new KyError(401);
      }
    }
  },
];

function haveResponse(request, response) {
  return (
    response.ok &&
    !['delete', 'head'].includes(request.method) &&
    response.status !== 204 &&
    response.body !== null
  );
}

export const afterResponse = [
  async function handleHttpError(request, options, response) {
    if (!response.ok) {
      if (response.status === 401) {
        // 返回 401 时删除本地 Token
        clearToken();
      } else if (response.body !== null && isJson(response)) {
        const { message, original } = await toJson(response);

        throw new KyError(response.status, { message, original });
      }

      throw new KyError(response.status);
    }
  },
  async function handle(request, options, response) {
    if (haveResponse(request, response) && isJson(response)) {
      const { error, code = 418, message, original } = await toJson(response);

      if (code === 10001) {
        clearToken();
        throw new KyError(401);
      }

      if (error) {
        throw new KyError(code, { message, original });
      }
    }
  },
];

const Ky = ky.create({
  timeout: 30000,
  prefixUrl: import.meta.env.API_BASE_URL, // .best-shot/env.toml
  retry: 0,
  hooks: { beforeRequest, afterResponse },
});

function KyErrorHandle(error) {
  if (error.name === 'AbortError') {
    throw new KyError(429);
  }

  if (error.name === 'TimeoutError' || error.message === 'Failed to fetch') {
    throw new KyError(408);
  }

  if (error.name === 'KyError') {
    if (error.code === 401) {
      clearToken();
    }

    throw error;
  }

  console.error(error);

  throw new KyError(418);
}

export const KyJson = new Proxy(
  {},
  {
    // eslint-disable-next-line consistent-return
    get(_, key) {
      if (['get', 'post', 'put', 'patch', 'delete'].includes(key)) {
        return (input, options = {}) => {
          if (options.searchParams) {
            // eslint-disable-next-line no-param-reassign
            options.searchParams = cleanObject(options.searchParams);
          }

          const call = () =>
            Ky[key](input, options).json().catch(KyErrorHandle);

          if (import.meta.env.CONFIG_NAME === 'alipay') {
            return call().catch(async (error) => {
              if (error.name === 'KyError' && error.code === 401) {
                await callMiniTwoWay('login').then(({ token } = {}) => {
                  Auth.token = token;
                });

                return call();
              }

              throw error;
            });
          }

          return call();
        };
      }
    },
  },
);
